/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import {
  getAuth,
  onAuthStateChanged,
  getIdToken,
  getIdTokenResult,
} from "firebase/auth";

import * as snippet from "@segment/snippet";
import * as Sentry from "@sentry/nextjs";
import Script from "next/script";

import "../styles/globals.css";

import fire, { BUILD_LEVEL } from "../fire";
import NavBar from "../components/NavBar";
import UserContext from "../components/UserContext";
import Footer from "../components/Footer";

import Page from "../components/Page";

function renderSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === "development") {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const [user, setUser] = useState(null);
  const auth = getAuth();

  const [userIdToken, setUserIdToken] = useState(null);

  const refreshAndSetToken = async () =>
    getIdToken(auth.currentUser, true).then(() =>
      getIdTokenResult(auth.currentUser).then((idToken) => {
        console.log({ idToken });
        setUserIdToken(idToken);
        return idToken;
      })
    );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        setUser(user);
        console.log({ user }, user.uid);
        // TODO: call update claims

        if (window.analytics) {
          window.analytics.identify(user.uid, {
            email: user.email,
            name: user.displayName,
          });
        }
        if (Sentry) {
          Sentry.setUser({
            email: user.email,
            name: user.displayName,
            id: user.uid,
          });
        }

        const functions = getFunctions(fire, "europe-west1");
        if (BUILD_LEVEL === "dev" || BUILD_LEVEL === "functionsDev") {
          const DEV_PORT = 5001;
          connectFunctionsEmulator(functions, `localhost`, DEV_PORT);
        }

        const updateClaims = httpsCallable(functions, "users-updateClaims");

        updateClaims(user?.uid)
          .then((result) => {
            return refreshAndSetToken();
          })
          .catch((err) => console.log("Error in update claims", { err }));
        // ...
      } else {
        setUser(null);
        setUserIdToken(null);
        // User is signed out
        // ...
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user?.emailVerified) {
      console.log("should have called email verified");

      const functions = getFunctions(fire, "europe-west1");
      if (BUILD_LEVEL === "dev" || BUILD_LEVEL === "functionsDev") {
        const DEV_PORT = 5001;
        connectFunctionsEmulator(functions, `localhost`, DEV_PORT);
      }

      const claimOwnership = httpsCallable(
        functions,
        "users-claimPersonalDataWithEmail"
      );
      claimOwnership()
        .then((result) => {
          console.log({ result });
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }, [user, user?.emailVerified]);

  const [customNavBarState, setCustomNavBarState] = useState(null);

  // on page change, if we go to index, set customNavBarState to null
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.pathname === "/") {
        setCustomNavBarState(null);
      }
    }
  }, [typeof window !== "undefined" && window.location.pathname]);

  return (
    <Page>
      <UserContext.Provider
        value={{ user, idToken: userIdToken, refreshToken: refreshAndSetToken }}
      >
        <div>
          {router.route.includes("/embed/") ? null : (
            <NavBar customNavBarState={customNavBarState} />
          )}
          <Script dangerouslySetInnerHTML={{ __html: renderSnippet() }} />
          <Component
            {...pageProps}
            setCustomNavBarState={setCustomNavBarState}
          />
          {router.route.includes("/embed/") ? null : <Footer />}
        </div>
      </UserContext.Provider>
    </Page>
  );
}

export default MyApp;
