import Router from "next/router";

// Track client-side page views with Segment
Router.events.on("routeChangeComplete", (url) => {
  window.analytics.page(url);
});

const Page = ({ children }) => children;

export default Page;
