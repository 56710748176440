import Link from "next/link";
import { Fragment, useContext, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { UserIcon } from "@heroicons/react/24/solid";
import { getAuth, signOut } from "firebase/auth";
import { useRouter } from "next/router";
import UserContext from "./UserContext";
import SignUpModal from "./SignUpModal";
import { SITE_LOGO, SITE_NAME } from "../config";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavBar = ({ customNavBarState }) => {
  const { user } = useContext(UserContext);
  const router = useRouter();
  const auth = getAuth();

  const [signUpOpen, setSignUpOpen] = useState(false);

  return (
    <nav
      style={{
        boxShadow: "rgb(0 0 0 / 8%) 0px 1px 12px !important",
        background: customNavBarState?.branding?.background || null,
      }}
      className="w-full border-b border-gray-200 shadow-sm py-4 z-40 relative bg-white"
    >
      <SignUpModal
        open={signUpOpen}
        setOpen={setSignUpOpen}
        onFinish={() => setSignUpOpen(false)}
        customBranding={customNavBarState}
      />
      <div className="max-w-7xl px-4 mx-auto w-full flex justify-between items-center">
        <Link href={customNavBarState?.backLink || "/"} legacyBehavior>
          {customNavBarState?.logo ? (
            <div className="text-xs text-gray-900 font-medium cursor-pointer flex flex-row flex-wrap items-end space-x-2">
              <img
                src={customNavBarState?.logo?.src}
                alt={customNavBarState?.logo?.alt}
                className="h-7 w-auto sm:h-10 flex-shrink-0"
              />
              <div className="border border-primary rounded-full px-2 text-center">Powered by {SITE_NAME}</div>
            </div>
          ) : (
            <div className="md:flex items-center">
              {SITE_LOGO}
            </div>
          )}
        </Link>
        <div className="flex justify-center items-center">
          {user?.uid ? null : (
            <button
              type="button"
              onClick={() => setSignUpOpen(true)}
              className="bg-primary px-4 py-2 rounded hover:bg-opacity-70 hover:shadow text-white font-medium text-sm mr-4 md:mr-6"
              style={{
                backgroundColor: customNavBarState?.branding?.primary || null,
              }}
            >
              Sign Up / Log In
            </button>
          )}

          <Menu as="div" className="ml-1 relative flex-shrink-0">
            <div className="flex">
              <Menu.Button className="border-2 border-primary md:hover:bg-primary/20 transition-colors duration-200 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                <span className="sr-only">Open user menu</span>
                {user?.photoURL ? (
                  <img
                    className="h-8 w-8 rounded-full"
                    src={user.photoURL || ""}
                    alt=""
                  />
                ) : (
                  <div
                    style={{
                      backgroundColor:
                        (user?.displayName || user?.email) &&
                        customNavBarState?.branding?.primary,
                    }}
                    className={`rounded-full capitalize font-medium flex items-center justify-center h-8 w-8 mt-[-1px] mb-[-1px] ml-[-1px] ${
                      user?.displayName || user?.email
                        ? "bg-primary text-white"
                        : "bg-gray-300"
                    }`}
                  >
                    {user?.displayName || user?.email ? (
                      user?.displayName?.[0] || user?.email[0]
                    ) : (
                      <UserIcon className="h-5 w-5 rounded-full" />
                    )}
                  </div>
                )}
                {user?.uid && (
                  <div className="my-auto ml-2 mr-3">My account</div>
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="z-40 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                {user ? (
                  <>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          href="/profile"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          )}
                        >
                          My Profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          href="/bookings"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          )}
                        >
                          My Bookings
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          href="/outstanding"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          )}
                        >
                          Outstanding Payments
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() =>
                            signOut(auth).then(() => router.push("/"))
                          }
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                          )}
                        >
                          Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </>
                ) : (
                  <>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => setSignUpOpen(true)}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                          )}
                        >
                          Sign Up
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => setSignUpOpen(true)}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                          )}
                        >
                          Log In
                        </button>
                      )}
                    </Menu.Item>
                  </>
                )}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
