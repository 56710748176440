import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { BUILD_LEVEL as buildlevel } from "./config";

export const BUILD_LEVEL =
  process.env.NODE_ENV === "production" ? "production" : buildlevel;

const productionConfig = {
  apiKey: "AIzaSyA8FFWoOifdusbuoYB2ksneSlPl-GwoLJo",
  authDomain: "book.plinth.org.uk",
  storageBucket: "timetospare-123.appspot.com",
  projectId: "timetospare-123",
};

const stagingConfig = {
  apiKey: "AIzaSyAwnCvJCDBf7AyOp-2iu17bPQy7Cvwb8lY",
  projectId: "timetospare-staging",
  storageBucket: "timetospare-staging.appspot.com",
  authDomain: "timetospare-staging.firebaseapp.com",
};

let config;
if (process.env.NODE_ENV === "production") {
  config = productionConfig;
} else if (BUILD_LEVEL === "staging") {
  config = stagingConfig;
} else if (BUILD_LEVEL === "dev" || BUILD_LEVEL === "functionsDev") {
  config = stagingConfig;
} else if (BUILD_LEVEL === "production") {
  config = productionConfig;
}

const fire = !getApps().length ? initializeApp(config) : getApp();

const REGION = "europe-west1";
const DEV_PORT = 5001;
const fireFunctions = getFunctions(getApp(), REGION);
const fireDb = getFirestore();

if (BUILD_LEVEL === "dev" && !fireDb._settings.host) {
  connectFirestoreEmulator(fireDb, "localhost", 8080);
  connectFunctionsEmulator(fireFunctions, "localhost", DEV_PORT);
}
// fire.functions = () => fireFunctions;
// fire.firestore = () => fireDb;

export default fire;
