import React, { useContext, useEffect, useState } from "react";
import {
  ArrowRightIcon,
  ChevronLeftIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { doc, getFirestore, setDoc } from "@firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
  getIdToken,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  FacebookAuthProvider,
  getAdditionalUserInfo,
  Auth,
  User,
} from "firebase/auth";

import Modal from "./Modal";
import UserContext from "./UserContext";
import { SITE_NAME, SITE_URL } from "../config";
import GoogleIcon from "./Icons/GoogleIcon";
import FacebookIcon from "./Icons/FacebookIcon";

// Add interfaces for props
interface CustomBranding {
  logo?: {
    src: string;
    alt: string;
  };
  name?: string;
}

type Stage =
  | "initial"
  | "signup"
  | "login"
  | "email-verification"
  | "start-password-reset"
  | "finished-password-reset";

interface StageContentProps {
  stage: Stage;
  auth: Auth;
  email: string;
  setEmail: (email: string) => void;
  handleFinishClick: () => void;
  handlePasswordReset: (e: React.FormEvent) => void;
  FacebookButton: React.ReactNode;
  GoogleButton: React.ReactNode;
  handleEmailFirst: (e: React.FormEvent) => void;
  setError: (error: Error | null) => void;
  password: string;
  setName: (name: string) => void;
  setPassword: (password: string) => void;
  existingMethods: string[];
  methodActions: { [key: string]: React.ReactNode };
  user: User;
  customBranding?: CustomBranding;
}

interface SignUpModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onFinish: () => void;
  details?: {
    email?: string;
  };
  noModal?: boolean;
  redirect?: string;
  customBranding?: CustomBranding;
}

const StageContent: React.FC<StageContentProps> = ({
  stage,
  auth,
  email,
  setEmail,
  handleFinishClick,
  handlePasswordReset,
  FacebookButton,
  GoogleButton,
  handleEmailFirst,
  setError,
  password,
  setName,
  setPassword,
  existingMethods,
  methodActions,
  user,
  customBranding,
}) => {
  switch (stage) {
    case "finished-password-reset":
      return (
        <>
          <h1 className="font-medium text-2xl">Please check your email</h1>
          <div className="rounded-md bg-blue-50 p-4 mt-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  We've sent a link to {email}. If you can't see it, it may have
                  gone to spam.
                </p>
              </div>
            </div>
          </div>
          <button
            onClick={handleFinishClick}
            className="bg-white w-full border my-4 border-gray-400 rounded-md p-3 flex items-center justify-center text-sm font-medium text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            OK
          </button>
        </>
      );
    case "start-password-reset":
      return (
        <>
          <p className="text-gray-800 text-md leading-snug">
            Add the email address on your account, and we’ll email you a link to
            reset your password.
          </p>
          <form onSubmit={handlePasswordReset}>
            <p id="email-info" className="sr-only">
              Password
            </p>
            <input
              type="email"
              placeholder="Email"
              autoCapitalize="none"
              aria-required="true"
              onChange={(e) => setEmail(e.target.value)}
              aria-labelledby="email-info"
              className="w-full py-3 px-3 rounded-lg border border-gray-400 mt-4"
            />
            <button type="submit" className="btn-primary w-full mt-4 py-3">
              Send Reset Link
            </button>
          </form>
        </>
      );
    case "initial":
      return (
        <>
          <div className="flex flex-col md:flex-row md:items-center space-x-2 mb-1">
            {customBranding?.logo && (
              <img
                src={customBranding?.logo?.src}
                alt={customBranding?.logo?.alt}
                className="h-8 max-w-[128px] w-auto pb-1 sm:h-10 md:pb-0"
              />
            )}
            <h1 className="font-medium text-2xl mb-0">
              Create an account / Log in
            </h1>
          </div>
          {customBranding && (
            <h2 className="mb-2 text-gray-700">
              Create an account or login so that you can make your bookings for
              <span className="font-bold text-black">
                &nbsp;{customBranding?.name}.
              </span>
            </h2>
          )}
          {!customBranding && (
            <h2 className="mb-2 text-gray-700">
              Lets you change and cancel your bookings and saves forms for the
              future.
            </h2>
          )}
          <form onSubmit={handleEmailFirst} className="">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full pb-3 px-3 rounded-md border-2 border-gray-400 focus:border-primary transition-colors duration-200 mt-2"
              placeholder="Sign up/log in with email"
            />
            <button
              type="button"
              onClick={handleEmailFirst}
              className="btn-primary mt-4 px-3 py-2 border-2 text-sm space-x-1 rounded-md w-full mb-4"
            >
              <span>Signup / Login</span>
              <ArrowRightIcon className="h-5 w-5" />
            </button>
          </form>
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-2 bg-white text-sm text-gray-500">or</span>
            </div>
          </div>
          <div className="flex flex-col space-y-4 mt-4">
            {FacebookButton}
            {GoogleButton}
          </div>
        </>
      );
    case "signup":
      return (
        <>
          <h1 className="font-medium text-2xl">
            Create a new {SITE_NAME} account
          </h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              createUserWithEmailAndPassword(auth, email, password)
                .then(() => {
                  setError(null);
                })
                .catch((err) => {
                  setError(err);
                });
            }}
          >
            <input
              type="text"
              placeholder="Your Full Name"
              className="w-full py-3 px-3 rounded-lg border border-gray-400 mt-4"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              autoCapitalize="none"
              aria-required="true"
              onChange={(e) => setPassword(e.target.value)}
              aria-labelledby="password-info"
              className="w-full py-3 px-3 rounded-lg border border-gray-400 mt-4"
            />
            <button type="submit" className="btn-primary w-full mt-4 py-3">
              Create Account
            </button>
          </form>
        </>
      );
    case "login":
      return (
        <>
          <h1 className="font-medium text-2xl">
            Log in to your {SITE_NAME} Account
          </h1>
          {existingMethods.map((method) => methodActions[method])}
        </>
      );
    case "email-verification":
      console.log("hi");
      return (
        <>
          <h1 className="font-medium text-2xl">
            Please verify your email address
          </h1>
          <div className="rounded-md bg-blue-50 p-4 mt-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  We've sent a verification link to {user?.email}.
                </p>
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleFinishClick}
            className="bg-white w-full border my-4 border-gray-400 rounded-md p-3 flex items-center justify-center text-sm font-medium text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            OK
          </button>
        </>
      );
    default:
      return null;
  }
};

const SignUpModal: React.FC<SignUpModalProps> = ({
  open,
  setOpen,
  onFinish,
  details,
  noModal,
  redirect,
  customBranding,
}) => {
  const [stage, setStage] = useState<Stage>("initial");

  const auth = getAuth();

  const [error, setError] = useState(null);

  const checkIfIosWebview = () => {
    const userAgent = window?.navigator?.userAgent?.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
      if (safari) {
        return false;
      } else if (!safari) {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleUpdateUserDoc = async (user, passName) => {
    try {
      const db = getFirestore();
      // set the doc in User collection
      const userDocRef = doc(db, "User", user?.uid);
      const result = await setDoc(userDocRef, {
        Name: passName,
        Email: user?.email,
      });
      return result;
    } catch (err) {
      console.log({ err });
      // swallow
    }
  };

  const handlePopupSignin = (provider) => {
    if (checkIfIosWebview()) {
      signInWithRedirect(auth, provider);
    } else {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          const { isNewUser } = getAdditionalUserInfo(result);
          const user = result.user;

          if (isNewUser) {
            await handleUpdateUserDoc(user, user.displayName || "");
          }

          setError(null);
          onFinish();
        })
        .catch((error) => {
          console.log({ error });
          setError(error);
        });
    }
  };

  const handleClose = async () => {
    if (user?.uid && !user.emailVerified) {
      await getIdToken(auth.currentUser, true);
    }
    setOpen(false);
    clearState();
  };

  const handleFinishClick = () => {
    handleClose();
    onFinish();
  };

  const { user } = useContext(UserContext);

  const [name, setName] = useState("");

  useEffect(() => {
    if (user?.uid && name) {
      handleUpdateUserDoc(user, name)
        .then(() =>
          updateProfile(auth.currentUser, {
            displayName: name,
          })
        )
        .then(() => {
          if (user.email && !user?.emailVerified) {
            const site =
              process.env.NODE_ENV === "production"
                ? `https://book.${SITE_URL}`
                : "https://localhost:3001";
            let link = `${site}/verify-email`;
            if (redirect) {
              link = `${link}?redirect=${redirect}`;
            }
            const actionCodeSettings = {
              // URL you want to redirect back to. The domain (www.example.com) for this
              // URL must be in the authorized domains list in the Firebase Console.
              url: link,
              // This must be true.
              handleCodeInApp: true,
            };
            sendEmailVerification(auth.currentUser, actionCodeSettings)
              .then(() => {
                window.localStorage.setItem("emailForSignIn", email);
                // ...
                // TODO: Tell the user this is happening
                setStage("email-verification");
              })
              .catch((error) => {
                setError(error);
              });
          }
        });
    }
    if (user?.uid && user.email && !user?.emailVerified) {
    }
  }, [user?.uid]);

  const startPasswordReset = () => {
    setStage("start-password-reset");
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        setStage("finished-password-reset");
        setError(null);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const GoogleButton = (
    <button
      type="button"
      key="google"
      onClick={() => handlePopupSignin(new GoogleAuthProvider())}
      className="hover:bg-gray-100 text-sm justify-between font-medium flex w-full border-2 rounded p-3 border-gray-400 text-gray-700"
    >
      <GoogleIcon className="block h-5 w-5" />
      <span className="flex-1">Continue with Google</span>
    </button>
  );

  const FacebookButton = (
    <button
      type="button"
      key="fb"
      onClick={() => handlePopupSignin(new FacebookAuthProvider())}
      className="hover:bg-gray-100 text-sm justify-between font-medium flex w-full border-2 rounded p-3 border-gray-400 text-gray-700"
    >
      <FacebookIcon className="block h-5 w-5" />
      <span className="flex-1">Continue with Facebook</span>
    </button>
  );

  const [email, setEmail] = useState(details?.email || "");
  const [existingMethods, setExistingMethods] = useState([]);
  const handleEmailFirst = async (e) => {
    e.preventDefault();
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);

      if (methods.length === 0) {
        setStage("signup");
        setError(null);
      } else {
        setStage("login");
        setExistingMethods(methods);
        setError(null);
      }
    } catch (err) {
      setError(err);
    }
  };

  const title = () => {
    if (stage === "initial") {
      return "Sign up or log in";
    } else if (stage === "login") {
      return "Log in";
    } else if (stage === "signup") {
      return "Sign up";
    } else {
      return "Email Verification";
    }
  };

  useEffect(() => {
    if (details?.email) {
      setEmail(details.email);
    }
  }, [details?.email]);

  const [password, setPassword] = useState("");

  const clearState = () => {
    setStage("initial");
    setEmail("");
    setPassword("");
    setError(null);
  };

  const methodActions = {
    "facebook.com": FacebookButton,
    "google.com": GoogleButton,
    password: (
      <form
        key="password"
        onSubmit={(e) => {
          e.preventDefault();
          signInWithEmailAndPassword(auth, email, password)
            .then(() => {
              setOpen(false);
              clearState();
              onFinish();
            })
            .catch((err) => {
              setError(err);
            });
        }}
      >
        <p id="password-info" className="sr-only">
          Password
        </p>
        <input
          type="password"
          placeholder="Password"
          autoCapitalize="none"
          aria-required="true"
          onChange={(e) => setPassword(e.target.value)}
          aria-labelledby="password-info"
          className="w-full py-3 px-3 rounded-lg border border-gray-400 mt-4"
        />
        <button type="submit" className="btn-primary w-full mt-4 py-3">
          Log in
        </button>
        <button
          onClick={startPasswordReset}
          className="underline my-4 text-gray-700 text-md font-medium"
        >
          Forgotten your password?
        </button>
      </form>
    ),
  };

  const handleBack = () => {
    if (["initial"]?.includes(stage)) {
      setOpen(false);
      clearState();
    } else {
      setStage("initial");
    }
  };

  const content = (
    <>
      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Sorry, we encountered an error
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <ul role="list" className="list-disc pl-5 space-y-1">
                  <li>{error.message?.replace("Firebase: ", "")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="p-6 pt-4">
        <StageContent
          stage={stage}
          auth={auth}
          email={email}
          setEmail={setEmail}
          handleFinishClick={handleFinishClick}
          handlePasswordReset={handlePasswordReset}
          FacebookButton={FacebookButton}
          GoogleButton={GoogleButton}
          handleEmailFirst={handleEmailFirst}
          setError={setError}
          password={password}
          setName={setName}
          setPassword={setPassword}
          existingMethods={existingMethods}
          methodActions={methodActions}
          user={user}
          customBranding={customBranding}
        />
      </div>
    </>
  );

  if (noModal) {
    return content;
  } else
    return (
      <Modal
        open={open}
        setOpen={handleClose}
        hideClose
        title={null}
        dontClose={false}
      >
        <div className="text-center w-full font-medium relative px-6 py-4 border-b border-gray-200">
          <button
            type="button"
            onClick={handleBack}
            className="flex items-center justify-center absolute left-0 ml-4 -mt-1.5 h-10 w-10  rounded-full hover:bg-gray-100"
          >
            {["initial"]?.includes(stage) ? (
              <XMarkIcon className="h-6" />
            ) : (
              <ChevronLeftIcon className="h-6" />
            )}
          </button>
          {customBranding && <span>{customBranding?.name} - </span>}
          {title()}
        </div>
        {content}
      </Modal>
    );
};

// Remove PropTypes as we're using TypeScript now
export default SignUpModal;
