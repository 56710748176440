/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";

function Modal({
  title,
  open,
  setOpen,
  className,
  hideClose,
  dontClose,
  children,
}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 md:h-screen md:overflow-y-hidden overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={dontClose ? () => {} : setOpen}
      >
        <div className="flex items-start md:items-end justify-center md:h-screen md:min-h-auto min-h-screen md:py-4 md:px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`${className} md:max-h-[90vh] inline-block w-full align-bottom bg-white md:rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:align-middle sm:max-w-xl`}
            >
              {!hideClose && (
                <div className={classNames({ "justify-between": title !== undefined }, "flex flex-row items-center justify-end p-4 pb-0 w-full pt-2")}>
                  {title && <h1 className="mb-0 text-lg font-medium">{title}</h1>}
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="flex items-center justify-center ml-2 mt-1 h-10 w-10 rounded-full hover:bg-gray-100"
                  >
                    <XMarkIcon className="h-8" />
                  </button>
                </div>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  className: PropTypes.string,
};

Modal.defaultProps = {
  className: undefined,
};

export default Modal;
