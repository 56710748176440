export const BUILD_LEVEL = "production";

export const SITE_NAME = "plinth";
export const SITE_URL = "plinth.org.uk";
export const SITE_LOGO = (
  // eslint-disable-next-line @next/next/no-html-link-for-pages
  <a className="z-30" href="/">
    <img src="/plinth-logo.svg" alt="" />
  </a>
);

export const NON_LINK_SITE_LOGO = (
  <div className="z-30">
    <img src="/plinth-logo.svg" alt="" />
  </div>
);
