import Link from "next/link";
import { SITE_LOGO, SITE_URL } from "../config";

const Footer = () => (
  <footer
    className="bg-white print:hidden border-t border-gray-200"
    aria-labelledby="footerHeading"
  >
    <h2 id="footerHeading" className="sr-only">
      Footer
    </h2>
    <div className="max-w-7xl mx-auto py-8 px-4">
      <div className="xl:grid xl:grid-cols-3 xl:gap-8">
        <div className="xl:col-span-1">
          <div className="text-xl cursor-pointer hover:text-willblue mb-3">
            {SITE_LOGO}
          </div>
          <p className="text-gray-700 text-base mb-6">
            The Community Impact Platform
          </p>
          <div className="flex space-x-6 mb-8">
            <a
              href="https://twitter.com/getTimetoSpare"
              className="text-gray-600 hover:text-gray-700"
            >
              <span className="sr-only">Twitter</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/time-to-spare/"
              className="text-gray-600 hover:text-gray-700"
            >
              <span className="sr-only">LinkedIn</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
        <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">
                Free for Charities
              </h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <a
                    href={`https://${SITE_URL}/features/monitoring-impact`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Monitoring & Impact Measurement
                  </a>
                </li>
                <li>
                  <a
                    href={`https://${SITE_URL}/features/case-management`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Case Management
                  </a>
                </li>
                <li>
                  <a
                    href={`https://${SITE_URL}/features/food-choices`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Food Project Management
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">
                For Funders
              </h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <a
                    href={`https://${SITE_URL}/features/referrals`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Referral Network
                  </a>
                </li>
                <li>
                  <a
                    href={`https://${SITE_URL}/features/volunteering`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Volunteering
                  </a>
                </li>
                <li>
                  <a
                    href={`https://${SITE_URL}/features/monitoring-reporting`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Monitoring & Reporting
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">
                Company
              </h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <a
                    href={`https://${SITE_URL}/about`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href={`https://blog.${SITE_URL}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Blog
                  </a>
                </li>
                <li>
                  <a
                    href={`https://${SITE_URL}/team`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Team
                  </a>
                </li>
              </ul>
            </div>
            <div className="mt-12 md:mt-0">
              <h3 className="text-sm font-semibold text-gray-600 tracking-wider uppercase">
                Legal
              </h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <a
                    href="https://docs.google.com/document/d/1sUIW3yEgS3g0XOj3kAeK0hLk3DI65__5QoT7PVXIIiI/edit"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Privacy
                  </a>
                </li>
                <li>
                  <a
                    href={`https://${SITE_URL}/terms`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Terms
                  </a>
                </li>
                <li>
                  <Link
                    href="/accessibility-statement"
                    className="text-base text-gray-700 hover:text-gray-900"
                  >
                    Accessibility
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 border-t border-gray-200 pt-8">
        <p className="text-base text-gray-600 xl:text-center">
          © {new Date().getFullYear()} Time to Spare Ltd trading as plinth. All rights reserved.
          Space 4, 113-115 Fonthill Road, N4 3HH • Reg No.{" "}
          <a
            className="text-blue-500"
            href="https://beta.companieshouse.gov.uk/company/11530023"
          >
            11530023
          </a>
        </p>
      </div>
    </div>
  </footer>
);

export default Footer;
